/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, Form } from "reactstrap";
import {
  Autocomplete,
  TextField,
  Typography,
  styled,
  Paper,
  Button,
} from "@mui/material";
import { ErrorMessage } from "../../common/Error";
import { useSelector } from "react-redux";
import { useLandDetailHooks } from "./useLandDetailHooks";
import TableContainer from "../../Component/TableContainer";
import DeleteModal from "../../Component/DeleteModal";
import VirtualizeAutoComplete from "../../common/VirtualizedAutoComplete";
import { Cancel, Save, SaveAs } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { GridAction } from "../../common/useGridAction";
import { usePermissionHooks } from "../../common/useCheckPermission";

const LandDetail = () => {
  const {
    register,
    errors,
    rowData,
    formData,
    handleChange,
    handleSubmit,
    onSubmit,
    setEditModal,
    farmerDropdownList,
    farmerLandList,
    onFarmerchange,
    onLandchange,
    onCancelClick,
    onGridDelete,
    setDeleteModal,
    deleteModal,
    deleteLoading,
    setSingleData,
    setDeleteId,
    handleActualLandSMChange,
    handleActualLandbighaChange,
    handlePlantationLandSMChange,
    handlePlantationLandbighaChange,
    loading,
    productlist,
    deleteId,
    onProductchange,
  } = useLandDetailHooks();

  const navigate = useNavigate();
  const location = useLocation();
  const VendorDetails = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    padding: "5px 10px",
    lineHeight: "60px",
  }));

  const permission = usePermissionHooks(location);
  const column = useMemo(
    () => [
      {
        Header: "Land / Plot No",
        accessor: "landplotno",
      },
      {
        Header: "Land Name",
        accessor: "landname",
      },
      {
        Header: "Product",
        accessor: "productName",
      },
      // {
      //   Header: "Land (Sq. Mt.)",
      //   accessor: "actuallandsm",
      // },
      // {
      //   Header: "Land (vigha)",
      //   accessor: "actuallandbigha",
      // },
      {
        Header: "Plantation (Sq. Mt.)",
        accessor: "plantationlandbigha",
      },
      {
        Header: "Plantation (Vigha)",
        accessor: "plantationlandsm",
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            setEditModal(true);
            setSingleData(cellProps.row.original);
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original);
          },
        }),
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Land Detail Master"}
      loading={loading}
      backBtnTitle={"BACK TO LIST"}
      onClick={() => navigate("/farminglandlist")}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mx-3 my-2 g-1">
          <Col lg={5} md={6} sm={12}>
            <VirtualizeAutoComplete
              id="farmerId"
              name="farmerId"
              size="small"
              options={farmerDropdownList}
              clearText={false}
              value={formData?.selectedFarmer}
              error={errors?.isactive}
              onChange={onFarmerchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Farmer"
                  size="small"
                />
              )}
            />

            {errors?.farmerId && (
              <ErrorMessage message={errors?.farmerId?.message} />
            )}
          </Col>
          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="surveynoId"
              name="surveynoId"
              size="small"
              options={farmerLandList}
              clearText={true}
              value={formData?.selectedSurveyno}
              error={errors?.surveynoId}
              onChange={onLandchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Survey Number"
                  size="small"
                />
              )}
            />
            {errors?.surveynoId && (
              <ErrorMessage message={errors?.surveynoId?.message} />
            )}
          </Col>
          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              name="productId"
              size="small"
              options={productlist}
              clearText={true}
              value={formData?.selectedProduct}
              error={errors?.productId}
              onChange={onProductchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Product"
                  size="small"
                />
              )}
            />
            {errors?.productId && (
              <ErrorMessage message={errors?.productId?.message} />
            )}
          </Col>
          {formData?.surveynoId > 0 && (
            <Col lg={12} md={12} sm={12} className="mb-2">
              <VendorDetails
                elevation={3}
                className="d-flex align-items-center"
                // severity="info"
                // color="info"
              >
                <Typography variant="h6" gutterBottom>
                  {formData?.selectedSurveyno?.ownername}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`  [Address - ${formData?.selectedSurveyno?.landAddress}]`}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`  [Register Land - ${formData?.selectedSurveyno?.registredland}] Square Meter ( ${formData?.selectedSurveyno?.regVigha} Bigha )`}
                </Typography>
              </VendorDetails>
            </Col>
          )}
        </Row>
        <Row className="mx-3 g-1">
          <Col lg={1} md={1} sm={12}>
            <TextField
              {...register("landplotno")}
              error={errors?.landplotno}
              id="landplotno"
              name="landplotno"
              value={formData?.landplotno}
              label="Land / Plot No"
              onChange={handleChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.landplotno && (
              <ErrorMessage message={errors?.landplotno?.message} />
            )}
          </Col>

          <Col lg={3} md={3} sm={12}>
            <TextField
              {...register("landname")}
              error={errors?.referenceno}
              id="landname"
              name="landname"
              value={formData?.landname}
              label="Land Name"
              onChange={handleChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.landname && (
              <ErrorMessage message={errors?.landname?.message} />
            )}
          </Col>

          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("actuallandsm")}
              error={errors?.actuallandsm}
              id="actuallandsm"
              name="actuallandsm"
              value={formData?.actuallandsm}
              label="Actual Land (sq. mt.)"
              onChange={handleActualLandSMChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.actuallandsm && (
              <ErrorMessage message={errors?.actuallandsm?.message} />
            )}
          </Col>

          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("actuallandbigha")}
              error={errors?.actuallandbigha}
              id="actuallandbigha"
              name="actuallandbigha"
              value={formData?.actuallandbigha}
              label="Actual Land (Bigha)"
              onChange={handleActualLandbighaChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.actuallandbigha && (
              <ErrorMessage message={errors?.actuallandbigha?.message} />
            )}
          </Col>

          {/* <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("plantationlandsm")}
              error={errors?.plantationlandsm}
              id="plantationlandsm"
              name="plantationlandsm"
              value={formData?.plantationlandsm}
              label="Plantation Land (sq. mt.)"
              onChange={handlePlantationLandSMChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.plantationlandsm && (
              <ErrorMessage message={errors?.plantationlandsm?.message} />
            )}
          </Col>

          <Col lg={2} md={2} sm={12}>
            <TextField
              {...register("plantationlandbigha")}
              error={errors?.plantationlandbigha}
              id="plantationlandbigha"
              name="plantationlandbigha"
              value={formData?.plantationlandbigha}
              label="Plantation Land (Bigha)"
              onChange={handlePlantationLandbighaChange}
              variant="standard"
              size="small"
              fullWidth
            />
            {errors?.plantationlandbigha && (
              <ErrorMessage message={errors?.plantationlandbigha?.message} />
            )}
          </Col> */}
        </Row>
        <Row className="mx-3 my-2 g-2">
          <Col lg={2} md={2} sm={12}>
            <Button
              type="submit"
              className="custom-btn-page-layout btn text-white btn-block custom-btn-success"
              startIcon={formData.id > 0 ? <SaveAs /> : <Save />}
              fullWidth
            >
              {formData.id > 0 ? ` Update` : `  Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              type="button"
              onClick={onCancelClick}
              className="custom-btn-page-layout btn text-white btn-block custom-btn-danger"
              startIcon={<Cancel />}
              fullWidth
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>

      <Col md={12}>
        <div
          className="d-grid"
          style={{ margin: "20px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={rowData}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
          />
        </div>
      </Col>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={() => onGridDelete(deleteId)}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default LandDetail;
