import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
//import PageNotFound from "../Pages/PageNotFound";
import AddFarmer from "../Pages/Farmer/AddFarmer";
import EditFarmer from "../Pages/Farmer/EditFarmer";
import ViewFarmer from "../Pages/Farmer/ViewFarmer";
import FarmerDocuments from "../Pages/Farmer/FarmerDocuments";
import AddCompany from "../Pages/Company/AddCompany";
import EditCompany from "../Pages/Company/EditCompany";
import ViewCompany from "../Pages/Company/ViewCompany";
import AddRole from "../Pages/Role/AddRole";
import ViewRole from "../Pages/Role/ViewRole";
import EditRole from "../Pages/Role/EditRole";
import AddLandRegistration from "../Pages/Farmer/AddLandRegistration";
import EditLandRegistration from "../Pages/Farmer/EditLandRegistration";
import ViewLandRegistration from "../Pages/Farmer/ViewLandRegistration";
import AddEmployee from "../Pages/Employee/AddEmployee";
import AddProductMaster from "../Pages/ProductMaster/AddProductMaster";
import AddVendorMaster from "../Pages/VendorMaster/AddVendorMaster";
import EditEmployee from "../Pages/Employee/EditEmployee";
import ViewEmployee from "../Pages/Employee/ViewEmployee";
import EditVendorMaster from "../Pages/VendorMaster/EditVendorMaster";
import ViewVendorMaster from "../Pages/VendorMaster/ViewVendorMaster";
import EditProductMaster from "../Pages/ProductMaster/EditProductMaster";
import ViewProductMaster from "../Pages/ProductMaster/ViewProductMaster";
import AddNewKitIssue from "../Pages/Farmer/AddNewKitIssue";
import ViewFarmerKits from "../Pages/Farmer/ViewFarmerKits";
import FarmerDashboard from "../Pages/Farmer/FarmerDashboard";
import FinalVerification from "../Pages/Farmer/FinalVerification";

// import AddIssueMaterial from "../Pages/FarmerManagement/IssueMaterial/AddIssueMaterial";
import YieldConfiguration from "../Pages/YieldConfiguration";
import AddPurchaseOrder from "../Pages/Purchase/PurchaseOrder/AddPurchaseOrder";
import EditPurchaseOrder from "../Pages/Purchase/PurchaseOrder/EditPurchaseOrder";
import ViewPurchaseOrder from "../Pages/Purchase/PurchaseOrder/ViewPurchaseOrder";
// import YieldEstimation from "../Pages/YieldEstimation";
import LoaderComponent from "../Component/LoaderComponent";

import {
  BagMaster,
  PrintBarcode,
  YieldEstimation,
  GRN,
  Dashboard,
  FarmerAllocationList,
  FarmerAllocation,
  SurveyList,
  SurveyManage,
  SurveyCreatorWidget,
  SurveyForm,
  Print,
  InvoiceList,
  Invoice,
  IncentiveScanner,
  UpdateIncentiveScan,
  FarmerPurchase,
  Updateweight,
  UpdateweightAdmin,
  ExportToTally,
  NGFarmerPrice,
  FarmerPurchase_1,
  NGPurchase,
  DocumentCreator,
  Variety,
} from "../Pages";
import QrReader from "../common/QRScanner";
import ScanResult from "../Pages/QRScanners/ScanResult";
import ScanBagUpdate from "../Pages/QRScanners/ScanBagUpdate";
import UpdateBagsOnlyScan from "../Pages/QRScanners/UpdateBagsOnlyScan";
import NGInvoice from "../Pages/FarmerInvoice/NGInvoice";
import DocumentPublishing from "../Pages/Settings/DocumentPublishing";
import PublishedDocumentList from "../Pages/Settings/PublishedList";
import EventPlanner from "../Pages/EventPlanner/Planner";
import PlannerList from "../Pages/EventPlanner/PlannerList";
import Agreement from "../Pages/Farmer/Agreement";
import IncentiveSchemeTypeList from "../Pages/Incentive Scheme/IncentiveSchemeTypeList";
import MainModule from "../Pages/ModulePages/MainModule";
import Module from "../Pages/ModulePages/Module";
import SubModule from "../Pages/ModulePages/SubModule";
import SurveyAnalyatics from "../Pages/Survey/SurveyAnalyatics";
// import PurchaseOrderList from "../Pages/Purchase/PurchaseOrder/PurchaseOrderList";
// import AddPriceListGroup from "../Pages/FarmerManagement/PriceList/AddPriceListGroup";

const Index = () => {
  const [roleBasedAuthRoute, setRoleBasedAuthRoute] = useState([]);
  const { permissions } = useSelector((state) => state.layout);

  useEffect(() => {
    if (permissions?.length > 0) {
      let arr = [];
      let addedPaths = new Set();

      authProtectedRoutes?.forEach((item) => {
        permissions?.forEach((er) => {
          if (er?.ModuleData?.length > 0) {
            // eslint-disable-next-line
            er?.ModuleData?.map((ed) => {
              // eslint-disable-next-line
              if (ed?.subModuleData?.length > 0) {
                // eslint-disable-next-line
                ed?.subModuleData?.map((qw) => {
                  if (qw?.path === item?.path && qw?.access) {
                    arr.push(item);
                    addedPaths.add(item?.path);
                  }

                  // Farmer Registration Check route
                  if (
                    qw?.path === "/farmerRegistration" &&
                    qw?.access &&
                    !addedPaths.has("/farmerRegistration")
                  ) {
                    arr.push({
                      path: "/farmerdashboard",
                      component: FarmerDashboard,
                    });

                    if (qw?.create) {
                      arr.push({
                        path: "/addfarmer",
                        component: AddFarmer,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editfarmer",
                        component: EditFarmer,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewfarmer",
                        component: ViewFarmer,
                      });
                    }
                    addedPaths.add("/farmerRegistration");
                  }

                  // Document Verification
                  if (
                    qw?.path === "/documentverification" &&
                    qw?.access &&
                    !addedPaths.has("/documentverification")
                  ) {
                    arr.push({
                      path: "/farmerdashboard",
                      component: FarmerDashboard,
                    });
                    if (qw?.create) {
                      arr.push({
                        path: "/farmerdocuments",
                        component: FarmerDocuments,
                      });
                    }
                    addedPaths.add("/documentverification");
                  }

                  // Land Registration Check route
                  if (
                    qw?.path === "/landregistration" &&
                    qw?.access &&
                    !addedPaths.has("/landregistration")
                  ) {
                    arr.push({
                      path: "/farmerdashboard",
                      component: FarmerDashboard,
                    });
                    if (qw?.create) {
                      arr.push({
                        path: "/addlandregistration",
                        component: AddLandRegistration,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editlandregistration",
                        component: EditLandRegistration,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewlandregistration",
                        component: ViewLandRegistration,
                      });
                    }
                    addedPaths.add("/landregistration");
                  }
                  if (
                    qw?.path === "/kitissue" &&
                    qw?.access &&
                    !addedPaths.has("/kitissue")
                  ) {
                    arr.push({
                      path: "/farmerdashboard",
                      component: FarmerDashboard,
                    });
                    if (qw?.create) {
                      arr.push({
                        path: "/addnewkitissue",
                        component: AddNewKitIssue,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editkitissue",
                        component: EditLandRegistration,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewfarmerkits",
                        component: ViewFarmerKits,
                      });
                    }
                    addedPaths.add("/kitissue");
                  }

                  if (
                    qw?.path === "/finalverification" &&
                    qw?.access &&
                    !addedPaths.has("/finalverification")
                  ) {
                    arr.push({
                      path: "/finalverification",
                      component: FinalVerification,
                    });
                    arr.push({
                      path: "/farmerdashboard",
                      component: FarmerDashboard,
                    });

                    addedPaths.add("/finalverification");
                  }

                  // Employee Check route
                  if (
                    qw?.path === "/employeeList" &&
                    qw?.access &&
                    !addedPaths.has("/employeeList")
                  ) {
                    if (qw?.create) {
                      arr.push({
                        path: "/addemployee",
                        component: AddEmployee,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editemployee",
                        component: EditEmployee,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewemployee",
                        component: ViewEmployee,
                      });
                    }
                    addedPaths.add("/employeeList");
                  }

                  // Product Master Check route
                  if (
                    qw?.path === "/productmaster" &&
                    qw?.access &&
                    !addedPaths.has("/productmaster")
                  ) {
                    if (qw?.create) {
                      arr.push({
                        path: "/addproductmaster",
                        component: AddProductMaster,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editproductmaster",
                        component: EditProductMaster,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewproductmaster",
                        component: ViewProductMaster,
                      });
                    }
                    addedPaths.add("/productmaster");
                  }

                  if (
                    qw?.path === "/rolemanagement" &&
                    qw?.access &&
                    !addedPaths.has("/rolemanagement")
                  ) {
                    if (qw?.create) {
                      arr.push({
                        path: "/addrole",
                        component: AddRole,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editrole",
                        component: EditRole,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewrole",
                        component: ViewRole,
                      });
                    }
                    addedPaths.add("/rolemanagement");
                  }

                  // Vendor Master Check route
                  if (
                    qw?.path === "/vendormaster" &&
                    qw?.access &&
                    !addedPaths.has("/vendormaster")
                  ) {
                    if (qw?.create) {
                      arr.push({
                        path: "/addvendormaster",
                        component: AddVendorMaster,
                      });
                    }
                    if (qw?.edit) {
                      arr.push({
                        path: "/editvendormaster",
                        component: EditVendorMaster,
                      });
                    }
                    if (qw?.print) {
                      arr.push({
                        path: "/viewvendormaster",
                        component: ViewVendorMaster,
                      });
                    }
                    addedPaths.add("/vendormaster");
                  }
                });
              } else {
                if (ed?.path === item?.path && ed?.access) {
                  arr.push(item);
                  addedPaths.add(item?.path);
                }

                // Company Check route

                // Role Check route
              }
            });
            // return filtererdDarta;
          } else {
            if (er?.path === item?.path && er?.access) {
              arr.push(item);
              addedPaths.add(item?.path);
            }

            if (
              er?.path === "/companylist" &&
              er?.access &&
              !addedPaths.has("/companylist")
            ) {
              if (er?.create) {
                arr.push({
                  path: "/addcompany",
                  component: AddCompany,
                });
              }
              if (er?.edit) {
                arr.push({
                  path: "/editcompany",
                  component: EditCompany,
                });
              }
              if (er?.print) {
                arr.push({
                  path: "/viewcompany",
                  component: ViewCompany,
                });
              }
              addedPaths.add("/companylist");
            }
          }
        });

        return item;
      });

      arr.push({ path: "/", component: Dashboard });
      // arr.push({ path: "/addissuematerial", component: AddIssueMaterial });
      arr.push({ path: "/yieldconfiguration", component: YieldConfiguration });
      arr.push({ path: "/yieldestimation", component: YieldEstimation });
      arr.push({ path: "/addpurchaseorder", component: AddPurchaseOrder });
      arr.push({ path: "/editpurchaseorder", component: EditPurchaseOrder });
      arr.push({ path: "/viewpurchaseorder", component: ViewPurchaseOrder });

      // arr.push({ path: "/yieldestimation", component: YieldEstimation});
      // arr.push({ path: "/purchaseorderlist", component: PurchaseOrderList });
      arr.push({ path: "/addgrn", component: GRN });
      arr.push({ path: "/bagmaster", component: BagMaster });
      arr.push({
        path: "/farmerallocationlist",
        component: FarmerAllocationList,
      });

      arr.push({
        path: "/managefarmerallocation",
        component: FarmerAllocation,
      });

      arr.push({
        path: "/print",
        component: Print,
      });
      arr.push({
        path: "/invoice",
        component: InvoiceList,
      });
      // if (user?.isnorthgujarat) {
      //   arr.push({
      //     path: "/farmerpurchases/add",
      //     component: NGPurchase,
      //   });

      //   arr.push({
      //     path: "/invoice/add",
      //     component: NGInvoice,
      //   });
      // } else {
      //   arr.push({
      //     path: "/farmerpurchases/add",
      //     component: FarmerPurchase_1,
      //   });

      //   arr.push({
      //     path: "/invoice/add",
      //     component: Invoice,
      //   });
      // }

      arr.push({
        path: "/farmerpurchases/addng",
        component: NGPurchase,
      });
      arr.push({
        path: "/farmerpurchases/add",
        component: FarmerPurchase_1,
      });
      arr.push({
        path: "/invoice/addng",
        component: NGInvoice,
      });
      arr.push({
        path: "/invoice/add",
        component: Invoice,
      });
      arr.push({
        path: "/qrscanner",
        component: QrReader,
      });
      arr.push({
        path: "/scanresult/:voucherno",
        component: ScanResult,
      });
      arr.push({
        path: "/bagupdatescanner",
        component: ScanBagUpdate,
      });
      arr.push({
        path: "/scanbagsupdate/:voucherno",
        component: UpdateBagsOnlyScan,
      });
      arr.push({
        path: "/incentivescanner",
        component: IncentiveScanner,
      });
      arr.push({
        path: "/scanincentiveupdate/:voucherno",
        component: UpdateIncentiveScan,
      });
      arr.push({
        path: "/farmerpurchases/gatepass",
        component: FarmerPurchase,
      });
      arr.push({
        path: "/updateweight",
        component: Updateweight,
      });
      arr.push({
        path: "/updateweightadmin",
        component: UpdateweightAdmin,
      });
      arr.push({
        path: "/tallyexport",
        component: ExportToTally,
      });
      arr.push({
        path: "/ngfarmerprice",
        component: NGFarmerPrice,
      });
      arr.push({
        path: "/publishing",
        component: DocumentPublishing,
      });
      arr.push({
        path: "/publishedlist",
        component: PublishedDocumentList,
      });
      arr.push({
        path: "/documentcreator",
        component: DocumentCreator,
      });
      arr.push({
        path: "/variety",
        component: Variety,
      });

      arr.push({
        path: "/SurveyManage",
        component: SurveyManage,
      });

      arr.push({
        path: "/SurveyForm",
        component: SurveyForm,
      });

      arr.push({
        path: "/eventplanner",
        component: EventPlanner,
      });
      arr.push({
        path: "/eventlist",
        component: PlannerList,
      });
      arr.push({
        path: "/farmersurvey",
        component: SurveyForm,
      });
      arr.push({
        path: "/incentiveSchemeType",
        component: IncentiveSchemeTypeList,
      });

      arr.push({
        path: "/mainmodule",
        component: MainModule,
      });
      arr.push({
        path: "/module",
        component: Module,
      });
      arr.push({
        path: "/submodule",
        component: SubModule,
      });
      arr.push({
        path: "/surveyreport",
        component: SurveyAnalyatics,
      });
      // arr.push({
      //   path: "/agreement/:id",
      //   component: Agreement,
      // });

      // arr.push({ path: "/printbarcode", component: PrintBarcode });
      // arr.push({ path: "/purchaseorderlist", cAddGrnomponent: PurchaseOrderList });

      setRoleBasedAuthRoute(arr);
    } else {
      setRoleBasedAuthRoute([]);
    }
  }, [permissions]);

  return (
    <Suspense fallback={<LoaderComponent />}>
      <Routes>
        <Route element={<ProtectedRoute routesData={authProtectedRoutes} />}>
          {permissions?.length > 0
            ? roleBasedAuthRoute?.map((item, i) => {
                return (
                  <Route
                    key={i}
                    path={item.path}
                    exact
                    Component={item?.component}
                  />
                );
              })
            : authProtectedRoutes?.map((item, i) => {
                return (
                  <Route
                    key={i}
                    path={item.path}
                    exact
                    Component={item?.component}
                  />
                );
              })}
        </Route>

        {publicRoutes?.map((item, i) => {
          return (
            <Route key={i} path={item.path} exact Component={item?.component} />
          );
        })}

        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Routes>
    </Suspense>
  );
};

export default Index;
