import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Row } from "reactstrap";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";

import PageLayout from "../../Component/PageLayout";
import TableContainer from "../../Component/TableContainer";
import { useYieldEstimationConfigHooks } from "./useYieldEstimationConfigHooks";
import { ErrorMessage } from "../../common/Error";
import { GridAction } from "../../common/useGridAction";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save } from "@mui/icons-material";
import { keys } from "../../config/keys";

const YieldEstimationConfig = () => {
  const {
    register,
    formData,
    rowData,
    handleChange,
    errors,
    resetFormData,
    handleSubmit,
    onSubmit,
    oncompanyunitdropdownchange,
    onproductdropdownchange,
    onGridEdit,
    onGridDelete,
    productlist,
    companyunitlist,
  } = useYieldEstimationConfigHooks();

  const { permissions } = useSelector((state) => state.layout);
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.path === "/yieldestimationconfig") {
          permissionsObj = ed;
        }
      });
      return filtererdDarta;
    }
  });

  const [loading, setLoading] = useState(false);

  const onDeleteClick = (data) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: `Are you sure to delete this?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onGridDelete(data);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const column = useMemo(
    () => [
      {
        Header: "Sr No",
        accessor: "SrNo",
        filterable: false,
      },
      {
        Header: "Company Unit",
        accessor: "unitName",
        filterable: false,
      },
      {
        Header: "Product Code",
        accessor: "partNo",
        filterable: false,
      },
      {
        Header: "Product",
        accessor: "productName",
        filterable: false,
      },
      {
        Header: "Limit Per Bigha",
        accessor: "limitperbigha",
        filterable: false,
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
      {
        ...GridAction({
          permission: permissionsObj,
          onEditClick: (cellProps) => {
            onGridEdit(cellProps.row.original);
          },
          onDeleteClick: (cellProps) => {
            onDeleteClick(cellProps.row.original);
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout title={"Yield Estimation Configuration"} loading={loading}>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-1">
        <Row>
          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="companyunitid"
              name="companyunitid"
              size="small"
              options={companyunitlist}
              value={formData.selectedcompanyunit}
              onChange={oncompanyunitdropdownchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Company Unit"
                  size="small"
                  {...textFieldProps}
                />
              )}
            />
          </Col>

          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="productid"
              name="productid"
              size="small"
              options={productlist}
              value={formData.selectedproduct}
              onChange={onproductdropdownchange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Product"
                  size="small"
                  {...textFieldProps}
                />
              )}
            />
          </Col>

          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("limitperbigha")}
                error={errors?.limitperbigha}
                id="limitperbigha"
                name="limitperbigha"
                label="Limit Per Bigha (In KG)"
                type="number"
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                {...textFieldProps}
              />
              {errors?.limitperbigha && (
                <ErrorMessage message={errors?.limitperbigha?.message} />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-success"
              fullWidth
              type="submit"
              size="small"
              startIcon={<Save />}
            >
              {` Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-danger btn btn-sm text-white btn-block"
              type="button"
              onClick={resetFormData}
              startIcon={<Cancel />}
              fullWidth
              size="small"
            >
              {` Cancel`}
            </Button>
          </Col>
        </Row>
      </Form>

      <Col md={12}>
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{ margin: "0 20px 20px 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={rowData}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        )}
      </Col>
    </PageLayout>
  );
};

export default YieldEstimationConfig;
