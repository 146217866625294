import Login from "../Pages/Authentication/Login";
import CompanyList from "../Pages/Company/CompanyList";
import CountryList from "../Pages/Country/CountryList";
import AddFarmer from "../Pages/Farmer/AddFarmer";
import FarmerDashboard from "../Pages/Farmer/FarmerDashboard";
import FarmerRegistration from "../Pages/Farmer/FarmerRegistration";
import DistrictList from "../Pages/District/DistrictList";
import StateList from "../Pages/State/StateList";
import DocumentVerification from "../Pages/Farmer/DocumentVerification";
import LandRegistration from "../Pages/Farmer/LandRegistration";
import AddNewKitIssue from "../Pages/Farmer/AddNewKitIssue";
import TalukaList from "../Pages/Taluka/TalukaList";
import KitIssueList from "../Pages/Farmer/KitIssueList";
import FinalVerification from "../Pages/Farmer/FinalVerification";
import CityList from "../Pages/City/CityList";
import VillageList from "../Pages/Village/VillageList";
import FarmerDocuments from "../Pages/Farmer/FarmerDocuments";
import EmployeeList from "../Pages/Employee/EmployeeList";
import DepartmentList from "../Pages/Department/DepartmentList";
import DesignationList from "../Pages/Designation/DesignationList";
import RoleManagement from "../Pages/Role/RoleManagement";
import IncentiveSchemeList from "../Pages/IncentiveScheme/IncentiveSchemeList";
import EditFarmer from "../Pages/Farmer/EditFarmer";
import ViewFarmer from "../Pages/Farmer/ViewFarmer";
import ProductTypeMaster from "../Pages/ProductMaster/ProductTypeMaster";
import groupMaster from "../Pages/ProductMaster/groupMaster";
import categoryMaster from "../Pages/ProductMaster/categoryMaster";
import UnitMaster from "../Pages/ProductMaster/UnitMaster";
import ProductList from "../Pages/ProductMaster/ProductList";
import VendorMaster from "../Pages/VendorMaster/VendorMaster";
import TrainingMaster from "../Pages/TrainingMaster/TrainingMaster";
import VillageMapping from "../Pages/FarmerManagement/VillageMapping/VillageMapping";
import FarmerWiseAllocation from "../Pages/FarmerManagement/FarmerWiseAllocation/FarmerWiseAllocation";
import VillageWiseFarmerAllocation from "../Pages/FarmerManagement/VillageWiseFarmerAllocation/VillageWiseFarmerAllocation";
import TransportMaster from "../Pages/FarmerManagement/TransportMaster/TransportMaster";
import FarmerReport from "../Pages/Report/FarmerReport";
import AddPriceListGroup from "../Pages/FarmerManagement/PriceList/AddPriceListGroup";
import AddPriceList from "../Pages/FarmerManagement/PriceList/AddPriceList";
import AddIssueMaterial from "../Pages/FarmerManagement/IssueMaterial/AddIssueMaterial";
import BlockSurveyNoList from "../Pages/FarmerManagement/SurveyBlock/BlockSurveyNoList";
// import YieldConfiguration from "../Pages/YieldConfiguration";
import AddPurchaseOrder from "../Pages/Purchase/PurchaseOrder/AddPurchaseOrder";
import EditPurchaseOrder from "../Pages/Purchase/PurchaseOrder/EditPurchaseOrder";
import ViewPurchaseOrder from "../Pages/Purchase/PurchaseOrder/ViewPurchaseOrder";
import PurchaseOrderList from "../Pages/Purchase/PurchaseOrder/PurchaseOrderList";

import IncentiveSchemeTypeList from "../Pages/Incentive Scheme/IncentiveSchemeTypeList";
import {
  ViewGrnList,
  YieldConfiguration,
  YieldEstimationConfig,
  YieldEstimation,
  IncentiveSchemeUpdate,
  PurchaseInvoice,
  LandDetail,
  FarmingLandList,
  BagMaster,
  PrintBarcode,
  YieldEstimationList,
  BagIssueList,
  IssueBag,
  Dashboard,
  FarmerPurchaseList,
  FarmerPurchase,
  SurveyCreatorWidget,
  SurveyList,
  SurveyManage,
  SurveyForm,
  FarmerAllocationList,
  FarmerAllocation,
  SlotBooking,
  AppointmentBooking,
  WaterWasteManagement,
  FarmerPurchase_1,
  Print,
  InvoiceList,
  UpdateBagsOnly,
  UpdateIncentive,
  GateEntryScanner,
  BagUpdateScanner,
  IncentiveScanner,
  UpdateIncentiveScan,
  GatePassList,
  Updateweight,
  ExportToTally,
  NGFarmerPrice,
  Invoice,
  NGPurchase,
  DocumentCreator,
  Variety,
} from "../Pages";
import QrReader from "../common/QRScanner";
import ScanResult from "../Pages/QRScanners/ScanResult";
import ScanBagUpdate from "../Pages/QRScanners/ScanBagUpdate";
import UpdateBagsOnlyScan from "../Pages/QRScanners/UpdateBagsOnlyScan";
import NGInvoice from "../Pages/FarmerInvoice/NGInvoice";
import TemplateList from "../Pages/DocumentCreator/TemplateList";
import DocumentPublishing from "../Pages/Settings/DocumentPublishing";
import PublishedDocumentList from "../Pages/Settings/PublishedList";
import EventPlanner from "../Pages/EventPlanner/Planner";
import PlannerList from "../Pages/EventPlanner/PlannerList";
import DownloadFarmerAgreement from "../Pages/Farmer/DownloadFarmerAgreement";
import Agreement from "../Pages/Farmer/Agreement";
import FarmerSurveyList from "../Pages/Survey/FarmerSurveyList";
import MainModule from "../Pages/ModulePages/MainModule";
import Module from "../Pages/ModulePages/Module";
import SubModule from "../Pages/ModulePages/SubModule";
import SurveyAnalytics from "../Pages/Survey/SurveyAnalyatics";

const authProtectedRoutes = [
  { path: "/", component: Dashboard, role: ["admin"] },
  { path: "/companylist", component: CompanyList, role: ["admin"] },
  { path: "/countrylist", component: CountryList, role: ["admin"] },
  { path: "/districtlist", component: DistrictList, role: ["admin"] },
  { path: "/talukalist", component: TalukaList, role: ["admin"] },
  { path: "/statelist", component: StateList, role: ["admin"] },
  { path: "/citylist", component: CityList, role: ["admin"] },
  { path: "/villagelist", component: VillageList, role: ["admin"] },
  { path: "/farmerdashboard", component: FarmerDashboard, role: ["admin"] },
  { path: "/farmerdocuments", component: FarmerDocuments, role: ["admin"] },
  { path: "/mainmodule", component: MainModule, role: ["admin"] },
  { path: "/module", component: Module, role: ["admin"] },
  { path: "/submodule", component: SubModule, role: ["admin"] },
  {
    path: "/farmerRegistration",
    component: FarmerRegistration,
  },
  {
    path: "/addfarmer",
    component: AddFarmer,
  },
  {
    path: "/viewfarmer",
    component: ViewFarmer,
  },
  {
    path: "/editfarmer",
    component: EditFarmer,
  },
  {
    path: "/documentverification",
    component: DocumentVerification,
  },
  {
    path: "/landregistration",
    component: LandRegistration,
  },

  {
    path: "/trainingmaster",
    component: TrainingMaster,
  },

  {
    path: "/addnewkitissue",
    component: AddNewKitIssue,
  },
  {
    path: "/kitissue",
    component: KitIssueList,
  },
  {
    path: "/finalverification",
    component: FinalVerification,
  },
  {
    path: "/employeeList",
    component: EmployeeList,
  },

  {
    path: "/departmentlist",
    component: DepartmentList,
  },
  {
    path: "/designationlist",
    component: DesignationList,
  },
  {
    path: "/rolemanagement",
    component: RoleManagement,
  },
  {
    path: "/producttypemaster",
    component: ProductTypeMaster,
  },
  {
    path: "/incentiveschemelist",
    component: IncentiveSchemeList,
  },
  {
    path: "/groupmaster",
    component: groupMaster,
  },
  {
    path: "/categorymaster",
    component: categoryMaster,
  },
  {
    path: "/unitmaster",
    component: UnitMaster,
  },
  {
    path: "/productmaster",
    component: ProductList,
  },
  {
    path: "/vendormaster",
    component: VendorMaster,
  },
  {
    path: "/villagemapping",
    component: VillageMapping,
  },
  {
    path: "/farmerallocationfarmerwise",
    component: FarmerWiseAllocation,
  },
  {
    path: "/farmerallocationvillagewise",
    component: VillageWiseFarmerAllocation,
  },
  {
    path: "/transportmaster",
    component: TransportMaster,
  },
  {
    path: "/farmerreport",
    component: FarmerReport,
  },
  {
    path: "/pricelistgroup",
    component: AddPriceListGroup,
  },
  {
    path: "/pricelist",
    component: AddPriceList,
  },
  {
    path: "/addissuematerial",
    component: AddIssueMaterial,
  },
  {
    path: "/blocksurveynolist",
    component: BlockSurveyNoList,
  },
  {
    path: "/yieldconfiguration",
    component: YieldConfiguration,
  },
  {
    path: "/addpurchaseorder",
    component: AddPurchaseOrder,
  },
  {
    path: "/editpurchaseorder",
    component: EditPurchaseOrder,
  },
  {
    path: "/viewpurchaseorder",
    component: ViewPurchaseOrder,
  },
  {
    path: "/purchaseorderlist",
    component: PurchaseOrderList,
  },
  {
    path: "/grn",
    component: ViewGrnList,
  },
  {
    path: "/yieldestimationconfig",
    component: YieldEstimationConfig,
  },
  {
    path: "/yieldestimationlist",
    component: YieldEstimationList,
  },
  {
    path: "/yieldestimation",
    component: YieldEstimation,
  },
  {
    path: "/purchaseinvoice",
    component: PurchaseInvoice,
  },
  {
    path: "/incentiveschemeupdate",
    component: IncentiveSchemeUpdate,
  },
  {
    path: "/landdetail",
    component: LandDetail,
  },
  {
    path: "/farminglandlist",
    component: FarmingLandList,
  },
  {
    path: "/bagmaster",
    component: BagMaster,
  },
  {
    path: "/issuebaglist",
    component: BagIssueList,
  },
  {
    path: "/issuebag",
    component: IssueBag,
  },
  {
    path: "/farmerpurchases",
    component: FarmerPurchaseList,
  },
  {
    path: "/farmerpurchases/gatepasslist",
    component: GatePassList,
  },
  {
    path: "/farmerpurchases/gatepass",
    component: FarmerPurchase,
  },
  {
    path: "/farmerpurchases/add",
    component: FarmerPurchase_1,
  },
  {
    path: "/farmerpurchases/addng",
    component: NGPurchase,
  },
  {
    path: "/createsurvey",
    component: SurveyCreatorWidget,
  },
  {
    path: "/surveylist",
    component: SurveyList,
  },
  {
    path: "/survey",
    component: SurveyManage,
  },
  {
    path: "/takesurvey",
    component: FarmerSurveyList,
  },
  {
    path: "/farmersurvey",
    component: SurveyForm,
  },
  {
    path: "/farmerallocationlist",
    component: FarmerAllocationList,
  },
  {
    path: "/farmerallocation",
    component: FarmerAllocation,
  },
  {
    path: "/purchaseappointmentlist",
    component: SlotBooking,
  },
  {
    path: "/purchaseappointment",
    component: AppointmentBooking,
  },
  {
    path: "/waterwaste",
    component: WaterWasteManagement,
  },
  {
    path: "/print",
    component: Print,
  },
  {
    path: "/invoice",
    component: InvoiceList,
  },
  {
    path: "/invoice/add",
    component: Invoice,
  },
  {
    path: "/invoice/addng",
    component: NGInvoice,
  },
  {
    path: "/updatebags",
    component: UpdateBagsOnly,
  },
  {
    path: "/updateincentive",
    component: UpdateIncentive,
  },
  {
    path: "/qrscanner",
    component: QrReader,
  },
  {
    path: "/gatescanner",
    component: GateEntryScanner,
  },
  {
    path: "/scanresult/:voucherno",
    component: ScanResult,
  },
  {
    path: "/bagupdatescanner/",
    component: BagUpdateScanner,
  },
  {
    path: "/scanbagsupdate/:voucherno",
    component: UpdateBagsOnlyScan,
  },
  {
    path: "/incentivescanner",
    component: IncentiveScanner,
  },
  {
    path: "/scanincentiveupdate/:voucherno",
    component: UpdateIncentiveScan,
  },
  {
    path: "/updateweight",
    component: Updateweight,
  },
  {
    path: "/tallyexport",
    component: ExportToTally,
  },
  {
    path: "/ngfarmerprice",
    component: NGFarmerPrice,
  },
  {
    path: "/documentcreator",
    component: DocumentCreator,
  },
  {
    path: "/variety",
    component: Variety,
  },
  {
    path: "/templatelist",
    component: TemplateList,
  },
  {
    path: "/publishing",
    component: DocumentPublishing,
  },
  {
    path: "/publishedlist",
    component: PublishedDocumentList,
  },
  {
    path: "/eventplanner",
    component: EventPlanner,
  },
  {
    path: "/eventlist",
    component: PlannerList,
  },
  {
    path: "/incentiveSchemeType",
    component: IncentiveSchemeTypeList,
  },
  {
    path: "/surveyreport",
    component: SurveyAnalytics,
  },
];

const publicRoutes = [
  { path: "/login", component: Login },
  {
    path: "/printbarcode",
    component: PrintBarcode,
  },
  {
    path: "/agreement/:id",
    component: Agreement,
  },
];

export { authProtectedRoutes, publicRoutes };
