import React, { useMemo, useState, useCallback, useEffect } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Input, InputGroup, InputGroupText } from "reactstrap";
import TableContainer from "../../Component/TableContainer";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../Component/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import CustomPagination from "../../Component/CustomPagination";
import { useFarmerHooks } from "./useFarmerHooks";
import { get_farmer_list } from "../../redux/actions/farmer";
import {
  Cancel,
  Check,
  Clear,
  ControlPoint,
  Delete,
  Edit,
  Remove,
  Verified,
  Visibility,
} from "@mui/icons-material";
import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { keys } from "../../config/keys";

const KitIssueList = () => {
  const {
    layout: { permissions, token },
    farmer_list,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/kitissue") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (
    !permissionsObj?.edit &&
    !permissionsObj?.delete &&
    !permissionsObj?.print
  ) {
    initialState.hiddenColumns = ["Action"];
  }

  const [kitIssueList, setKitIssueList] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { loading, setLoading, farmerDataByIdAPi } = useFarmerHooks();

  // const getKitListAPi = useCallback(async (limit, offset) => {
  //   setLoading(true);
  //   try {
  //     const apiCall = await fetch(
  //       `${process.env.REACT_APP_API_URL}farmer/kitissuelist?limit=${limit}&offset=${offset}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,

  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const _data = await apiCall.json();

  //     if (_data?.code === "token_not_valid") {
  //       dispatch(logOutRequest());
  //       toast.error("Session Expired.");

  //       navigate("/login");
  //       setLoading(false);
  //     }

  //     if (_data?.status) {
  //       setLoading(false);
  //       setKitIssueList(_data?.farmerKitList);
  //       setTotalCount(_data?.paginationDetails?.count);
  //     } else {
  //       setLoading(false);

  //       toast.error(_data?.error);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   getKitListAPi(limit, offset);
  //   // eslint-disable-next-line
  // }, [getKitListAPi]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      get_farmer_list({
        onSuccess: () => setLoading(false),
        onError: () => setLoading(false),
      })
    );
  }, []);

  const getIconColor = (status) => {
    if (status === 1) return "success";
    else if (status === 2) return "warning";
    else return "error";
  };

  const column = useMemo(
    () => [
      // {
      //   Header: "Farmer ID",
      //   accessor: "id",
      //   filterable: false,
      // },
      {
        Header: "Code",
        accessor: "farmerCode",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: false,
      },
      {
        Header: "Village",
        accessor: "villageName",
        filterable: false,
      },
      {
        Header: "Created On",
        accessor: "kcreateddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
      // {
      //   Header: "Taluka",
      //   accessor: "farmerTaluka.talukaName",
      //   filterable: false,
      // },
      // {
      //   Header: "Dist",
      //   accessor: "farmerDistrict.districtName",
      //   filterable: false,
      // },
      // {
      //   Header: "State",
      //   accessor: "farmerState.stateName",
      //   filterable: false,
      // },
      {
        Header: "Pending Docs",
        accessor: "pendingdocs",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        // Cell: (cellProps) =>
        //   cellProps.row.original.documentsubmitted ? (
        //     <Check fontSize="medium" color="success" />
        //   ) : (
        //     <Clear fontSize="medium" color="error" />
        //   ),
        Cell: (cellProps) => {
          return cellProps.value > 0 ? (
            <Tooltip
              title={
                <List>
                  {cellProps.row.original.pendingdocuments
                    .split(",")
                    ?.map((x) => (
                      <ListItem style={{ padding: 0 }}>
                        <ListItemIcon>
                          <Cancel sx={{ color: red[500] }} />
                        </ListItemIcon>
                        <ListItemText>{x}</ListItemText>
                      </ListItem>
                    ))}
                </List>
              }
              placement="left"
            >
              {/* <Button variant="text" size="small">
                <Typography variant="body1">{cellProps.value}</Typography>{" "}
              </Button> */}
              <Chip
                label={cellProps.value}
                variant="filled"
                color={cellProps.value > 2 ? "error" : "warning"}
                size="small"
              />
            </Tooltip>
          ) : (
            <Remove fontSize="medium" />
          );
        },
      },
      {
        Header: "Doc Verified?",
        accessor: "documentverified",
        Filter: false,
        style: {
          "text-align": "center",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.documentverified >= 0 ? (
            <Verified
              fontSize="medium"
              color={getIconColor(cellProps.row.original.documentverified)}
            />
          ) : (
            <Remove fontSize="medium" />
          );
        },
      },

      {
        id: "Status",
        Header: "Kit Issued?",
        style: {
          "text-align": "center",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.kitissued > 0 ? (
            <Verified fontSize="medium" color={"success"} />
          ) : (
            <Remove fontSize="medium" />
          );
        },
      },
      {
        id: "Action",
        Header: "",
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {permissionsObj?.create && (
                  <IconButton
                    size="small"
                    color="success"
                    onClick={() => {
                      farmerDataByIdAPi(
                        cellProps.row.original.id,
                        "/addnewkitissue"
                      );
                    }}
                    disabled={cellProps.row.original.pendingdocs > 0}
                  >
                    <ControlPoint />
                  </IconButton>
                )}
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <IconButton
                    size="small"
                    color="success"
                    onClick={() => {
                      farmerDataByIdAPi(
                        cellProps.row.original.id,
                        "/addnewkitissue"
                      );
                    }}
                    disabled={
                      cellProps?.row?.original?.kitissued === 1 ? false : true
                    }
                  >
                    <Edit />
                  </IconButton>
                )}
                {/* View Button  */}
                {permissionsObj?.print && (
                  <IconButton
                    size="small"
                    color="info"
                    onClick={() =>
                      farmerDataByIdAPi(
                        cellProps.row.original.id,
                        "/viewfarmerkits"
                      )
                    }
                    disabled={
                      cellProps?.row?.original?.kitissued === 1 ? false : true
                    }
                  >
                    <Visibility />
                  </IconButton>
                )}

                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                    disabled={
                      cellProps?.row?.original?.kitissued === 1 ? false : true
                    }
                  >
                    <Delete />
                  </IconButton>
                )}
              </div>
            </div>
          );
        },
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("farmerId", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}farmer/deleteallkits`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        // getKitListAPi(limit, offset);
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);
    let formData = new FormData();

    formData.append("farmerName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }farmer/kitissuefilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setKitIssueList(_data?.filteredFarmerList);
        setTotalCount(_data?.filteredFarmerList?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
      console.log(error);
    }
  };

  return (
    <PageLayout title={"KIT Issue & Invoice Updation"} loading={loading}>
      <div className="m-4">
        {!loading && (
          <div className="d-grid" style={{ border: "1px solid #DEE2E6" }}>
            <TableContainer
              columns={column}
              data={farmer_list}
              isGlobalFilter={true}
              isAddUserList={false}
              showColumnFilters
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
            />
          </div>
        )}
      </div>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default KitIssueList;
