/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col, Row, FormGroup, Form } from "reactstrap";
import { Autocomplete, Button, TextField } from "@mui/material";
import { ErrorMessage } from "../../common/Error";
import TableContainer from "../../Component/TableContainer";
import { useSelector } from "react-redux";
import { statusOption } from "./../../hooks/StaticData";
import DeleteModal from "../../Component/DeleteModal";
import { useYieldConfigurationHooks } from "./useYieldConfigurationHooks";
import { SelectColumnFilter } from "../../Component/filters";
import { textFieldProps } from "../../common/commonControlProps";
import { Cancel, Save, Update } from "@mui/icons-material";
import { GridAction } from "../../common/useGridAction";
import { keys } from "../../config/keys";

const YieldConfiguration = () => {
  const {
    register,
    formData,
    handleChange,
    errors,
    handleSubmit,
    onSubmit,
    ondropdownchange,
    setEditModal,
    setSingleData,
    rowData,
    setDeleteModal,
    deleteModal,
    setDeleteId,
    deleteLoading,
    handleDeleteRequest,
    onCancelClick,
  } = useYieldConfigurationHooks();

  const { permissions } = useSelector((state) => state.layout);

  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.path === "/yieldconfiguration") {
          permissionsObj = ed;
        }
      });
      return filtererdDarta;
    }
  });
  const [loading] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  // const [configList, setConfigList] = useState([]);
  // const [limit, setLimit] = useState(5); // default limit
  // const [offset, setOffset] = useState(0); // default offset

  const column = useMemo(
    () => [
      {
        Header: "Configuration Name",
        accessor: "name",
        filterable: false,
      },

      {
        Header: "Bhukho",
        accessor: "bhukho",
        filterable: false,
      },
      {
        Header: "Gariyu",
        accessor: "gariyu",
        filterable: false,
      },
      {
        Header: "Dakru",
        accessor: "dakru",
        filterable: false,
      },

      {
        Header: "Estimated",
        accessor: "estimated",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "isStatus",
        filterable: false,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
      {
        ...GridAction({
          permission: permissionsObj,
          onEditClick: (cellProps) => {
            setEditModal(true);
            setSingleData(cellProps.row.original);
          },
          onDeleteClick: (cellProps) => {
            setDeleteModal(true);
            setDeleteId(cellProps.row.original.id);
          },
        }),
      },

      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout title={"Yeild Configuration"} loading={loading}>
      <Form onSubmit={handleSubmit(onSubmit)} className="m-4 g-2">
        <Row>
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("name")}
                error={errors?.name}
                id="name"
                name="name"
                label="Configuration Name"
                value={formData?.name}
                onChange={handleChange}
                {...textFieldProps}
              />
              {errors?.name && <ErrorMessage message={errors?.name?.message} />}
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("bhukho")}
                error={errors?.bhukho}
                id="bhukho"
                name="bhukho"
                value={formData?.bhukho}
                label="Bhukho in (%)"
                onChange={handleChange}
                {...textFieldProps}
              />
              {errors?.gariyu && (
                <ErrorMessage message={errors?.gariyu?.message} />
              )}
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("gariyu")}
                error={errors?.gariyu}
                id="gariyu"
                name="gariyu"
                value={formData?.gariyu}
                label="Gariyu in (%)"
                onChange={handleChange}
                {...textFieldProps}
              />
              {errors?.gariyu && (
                <ErrorMessage message={errors?.gariyu?.message} />
              )}
            </FormGroup>
          </Col>
          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("dakru")}
                error={errors?.dakru}
                id="dakru"
                name="dakru"
                value={formData?.dakru}
                label="Dakru in (%)"
                onChange={handleChange}
                {...textFieldProps}
              />
              {errors?.dakru && (
                <ErrorMessage message={errors?.dakru?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <FormGroup>
              <TextField
                {...register("estimated")}
                error={errors?.estimated}
                id="estimated"
                name="estimated"
                value={formData?.estimated}
                label="Estimated in (+/-)"
                onChange={handleChange}
                {...textFieldProps}
              />
              {errors?.estimated && (
                <ErrorMessage message={errors?.estimated?.message} />
              )}
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={12}>
            <Autocomplete
              id="isactive"
              name="isactive"
              size="small"
              options={statusOption}
              clearText={true}
              value={formData?.selStatus}
              error={errors?.isactive}
              onChange={ondropdownchange}
              renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label="Status" />
              )}
            />
            {errors?.isactive && (
              <ErrorMessage message={errors?.isactive?.message} />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-success"
              fullWidth
              type="submit"
              size="small"
              startIcon={formData.id > 0 ? <Update /> : <Save />}
            >
              {formData.id > 0 ? ` Update` : ` Save`}
            </Button>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <Button
              className="custom-btn-page-layout custom-btn-danger btn btn-sm text-white btn-block"
              type="button"
              onClick={onCancelClick}
              startIcon={<Cancel />}
              fullWidth
              size="small"
            >
              {` Cancel`}
            </Button>
          </Col>
          {/* <div className="" style={{ margin: "0px 30px" }}>
            <Col lg={3} md={4} sm={12}>
              <button className="custom-btn-page-layout btn text-white btn-block">
                <i className="fa fa-save" />
                {formData.id > 0 ? ` Edit` : ` Save`}
              </button>

              <button
                type="button"
                onClick={onCancelClick}
                className="custom-btn-page-layout btn text-white btn-block"
                style={{ margin: "0px 30px" }}
              >
                <i className="fa fa-cancel" />
                Cancel
              </button>
            </Col>
          </div> */}
        </Row>
      </Form>

      <Col md={12}>
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={rowData}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
              divClass={"table-responsive"}
            />
          </div>
        )}
      </Col>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />
    </PageLayout>
  );
};

export default YieldConfiguration;
