import React, { useState, useMemo, useCallback, useEffect } from "react";
import PageLayout from "../../Component/PageLayout";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import TableContainer from "../../Component/TableContainer";
import StateOption from "../../hooks/StateOptions";
import CountryOption from "../../hooks/CountryOption";
import DistrictOption from "../../hooks/DistrictOption";
import TalukaOption from "../../hooks/TalukaOption";
import DeleteModal from "../../Component/DeleteModal";
import { ClipLoader } from "react-spinners";
import CustomPagination from "../../Component/CustomPagination";
import EditVillage from "./EditVillage";
import { useDispatch, useSelector } from "react-redux";
import { logOutRequest } from "../../store/reducers/layoutReducer";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
import { commonActions } from "../../redux/actions";
import { keys } from "../../config/keys";
import { Button, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";
const VillageList = () => {
  const { permissions, token } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useBreakPoints();
  let permissionsObj = {};
  // eslint-disable-next-line
  permissions?.filter((er) => {
    if (er?.ModuleData?.length > 0) {
      // eslint-disable-next-line
      let filtererdDarta = er?.ModuleData?.filter((ed) => {
        if (ed?.subModuleData?.length > 0) {
          // eslint-disable-next-line
          ed?.subModuleData?.map((qw) => {
            if (qw?.path === "/villagelist") {
              permissionsObj = qw;
            }
          });
        }
      });
      return filtererdDarta;
    }
  });

  let initialState = {};

  if (!permissionsObj?.edit && !permissionsObj?.delete) {
    initialState.hiddenColumns = ["Action"];
  }

  const [villageList, setVillageList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(25); // default limit
  const [offset, setOffset] = useState(0); // default offset
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [districtArray, setDistrictArray] = useState([]);
  const [talukaArray, setTalukaArray] = useState([]);
  const [singleData, setSingleData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [inputData, setInputData] = useState({
    country: "",
    state: "",
    dist: "",
    taluka: "",
    villageName: "",
  });

  const [inputDataError, setInputDataError] = useState({
    country: false,
    state: false,
    dist: false,
    taluka: false,
    villageName: false,
  });

  const { getStateOption } = StateOption();
  const { getCountryOption } = CountryOption();
  const { getDistrictOption } = DistrictOption();
  const { getTalukaOption } = TalukaOption();

  const nonNumericRegex = /^[A-Za-z]+$/;

  const handleChangeInput = (name, value) => {
    if (name === "villageName") {
      if (nonNumericRegex.test(value) || value === "") {
        setInputData({ ...inputData, [name]: value });
      } else {
        toast.error("Type characters only");
      }
      if (value?.length > 0) {
        setInputDataError({ ...inputDataError, villageName: false });
      } else {
        setInputDataError({ ...inputDataError, villageName: true });
      }
    } else {
      if (name === "country") {
        getStateOption(value.value).then((res) => {
          setStateArray(res);
        });
        setInputData({
          ...inputData,
          [name]: value,
          state: "",
          dist: "",
          taluka: "",
          villageName: "",
        });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, country: false });
        } else {
          setInputDataError({ ...inputDataError, country: true });
        }
      } else if (name === "state") {
        getDistrictOption(value.value).then((res) => {
          setDistrictArray(res);
        });
        setInputData({
          ...inputData,
          [name]: value,
          dist: "",
          taluka: "",
          villageName: "",
        });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, state: false });
        } else {
          setInputDataError({ ...inputDataError, state: true });
        }
      } else if (name === "dist") {
        getTalukaOption(value.value).then((res) => {
          setTalukaArray(res);
        });
        setInputData({
          ...inputData,
          [name]: value,
          taluka: "",
          villageName: "",
        });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, dist: false });
        } else {
          setInputDataError({ ...inputDataError, dist: true });
        }
      } else if (name === "taluka") {
        setInputData({ ...inputData, [name]: value, villageName: "" });
        if (value?.label?.length > 0) {
          setInputDataError({ ...inputDataError, taluka: false });
        } else {
          setInputDataError({ ...inputDataError, taluka: true });
        }
      } else {
        setInputData({ ...inputData, [name]: value });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputData.country) {
      setInputDataError({ ...inputDataError, country: true });

      toast.error("County is Required!");
    } else if (!inputData.state) {
      setInputDataError({ ...inputDataError, state: true });

      toast.error("State is Required!");
    } else if (!inputData.dist) {
      setInputDataError({ ...inputDataError, dist: true });

      toast.error("Dist. is Required!");
    } else if (!inputData.taluka) {
      setInputDataError({ ...inputDataError, taluka: true });

      toast.error("Taluka Name is Required!");
    } else if (!inputData.villageName) {
      setInputDataError({ ...inputDataError, villageName: true });

      toast.error("Village Name is Required!");
    } else {
      setSubmitLoading(false);
      let formData = new FormData();

      formData.append("countryId", inputData.country.value);
      formData.append("stateId", inputData.state.value);
      formData.append("districtId", inputData.dist.value);
      formData.append("talukaId", inputData.taluka.value);
      formData.append("villageName", inputData.villageName);

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}admin1/addvillage`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const _data = await apiCall.json();

        if (_data?.code === "token_not_valid") {
          dispatch(logOutRequest());
          toast.error("Session Expired.");

          navigate("/login");
          setLoading(false);
        }

        if (_data?.status) {
          toast?.success(_data?.message);
          setSubmitLoading(false);
          setInputData({
            country: "",
            state: "",
            city: "",
            dist: "",
            taluka: "",
            villageName: "",
          });
          // getVillageListAPi(limit, offset);

          dispatch(
            commonActions.get_village_list({
              onSuccess: (res) => setVillageList(res),
            })
          );
        } else {
          setSubmitLoading(false);
          toast?.error(_data?.message);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast?.error(error);
      }
    }
  };

  // Get Village List
  const getVillageListAPi = useCallback(async (limit, offset) => {
    setLoading(true);
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}admin1/village?limit=${limit}&offset=${offset}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setVillageList(_data?.villageList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);

        toast.error(_data?.error);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //getVillageListAPi(limit, offset);
    dispatch(
      commonActions.get_village_list({
        onSuccess: (res) => setVillageList(res),
      })
    );
    getCountryOption().then((res) => {
      setCountryArray(res);
    });
    // eslint-disable-next-line
  }, [getVillageListAPi]);

  // handleDelete Btn
  const handleDeleteRequest = async () => {
    setDeleteLoading(true);
    let formData = new FormData();
    formData.append("id", deleteId);
    formData.append("isDelete", "Yes");

    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_API_URL}admin1/deletevillage`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        // getVillageListAPi(limit, offset);
        dispatch(
          commonActions.get_village_list({
            onSuccess: (res) => setVillageList(res),
          })
        );
        setDeleteModal(false);
        toast?.success(_data?.message);
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        setDeleteModal(false);

        toast.error(_data?.message);
      }
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Village",
        accessor: "villageName",
      },
      {
        Header: "Taluka",
        accessor: "talukaName",
        filterable: false,
      },
      {
        Header: "District",
        accessor: "districtName",
        filterable: false,
      },
      {
        Header: "State",
        accessor: "stateName",
        filterable: false,
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },

      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permissionsObj?.edit && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setEditModal(true);
                      setSingleData(cellProps.row.original);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_318)">
                        <path
                          d="M6.87549 2.5H2.50049C2.16897 2.5 1.85103 2.6317 1.6166 2.86612C1.38218 3.10054 1.25049 3.41848 1.25049 3.75V12.5C1.25049 12.8315 1.38218 13.1495 1.6166 13.3839C1.85103 13.6183 2.16897 13.75 2.50049 13.75H11.2505C11.582 13.75 11.9 13.6183 12.1344 13.3839C12.3688 13.1495 12.5005 12.8315 12.5005 12.5V8.125"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.563 1.56249C11.8116 1.31385 12.1489 1.17416 12.5005 1.17416C12.8521 1.17416 13.1893 1.31385 13.438 1.56249C13.6866 1.81113 13.8263 2.14836 13.8263 2.49999C13.8263 2.85162 13.6866 3.18885 13.438 3.43749L7.50049 9.37499L5.00049 9.99999L5.62549 7.49999L11.563 1.56249Z"
                          stroke="#3E7531"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_318">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
                {/* Delete Button */}
                {permissionsObj?.delete && (
                  <button
                    className="border-0"
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(cellProps.row.original.id);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_32_330)">
                        <path
                          d="M6.63507 -0.0178833C5.50545 -0.0178833 4.59795 0.92077 4.59795 2.05558V2.30769H2.30814C1.99026 2.30769 1.73122 2.56673 1.73122 2.88462V3.46154H1.1543V4.61539H2.30814V13.2692C2.30814 14.2183 3.08987 15 4.03891 15H10.962C11.911 15 12.6928 14.2183 12.6928 13.2692V4.61539H13.8466V3.46154H13.2697V2.88462C13.2697 2.56673 13.0106 2.30769 12.6928 2.30769H10.403V2.055C10.403 0.920771 9.49545 -0.0178833 8.36584 -0.0178833H6.63507ZM6.63507 1.17173H8.36584C8.82968 1.17173 9.21334 1.55019 9.21334 2.055V2.30769H5.78699V2.055C5.78699 1.55019 6.17064 1.17231 6.63449 1.17231L6.63507 1.17173ZM3.46199 4.61539H6.41872C6.49026 4.62289 6.56122 4.63327 6.63507 4.63327H8.36584C8.43968 4.63327 8.51007 4.62289 8.58218 4.61539H11.5389V13.2692C11.5389 13.594 11.2868 13.8462 10.962 13.8462H4.03891C3.7141 13.8462 3.46199 13.594 3.46199 13.2692V4.61539ZM4.61584 5.76923V12.6923H5.76968V5.76923H4.61584ZM6.92353 5.76923V12.6923H8.07737V5.76923H6.92353ZM9.23122 5.76923V12.6923H10.3851V5.76923H9.23122Z"
                          fill="#EC0505"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_32_330">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  const onSubmitModal = () => {
    // getVillageListAPi(limit, offset);
    dispatch(
      commonActions.get_village_list({
        onSuccess: (res) => setVillageList(res),
      })
    );

    setEditModal(false);
  };

  const onChangeSearch = async (value) => {
    setLoading(true);
    setSearchInput(value);
    let formData = new FormData();

    formData.append("villageName", value);

    try {
      const apiCall = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }admin1/villagefilter?limit=${5}&offset=${0}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const _data = await apiCall.json();

      if (_data?.code === "token_not_valid") {
        dispatch(logOutRequest());
        toast.error("Session Expired.");

        navigate("/login");
        setLoading(false);
      }

      if (_data?.status) {
        setLoading(false);
        setVillageList(_data?.villageList);
        setTotalCount(_data?.paginationDetails?.count);
      } else {
        setLoading(false);
        toast?.error(_data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast?.error(error);
    }
  };

  return (
    <PageLayout title={"Create Village"} loading={loading}>
      {permissionsObj?.create && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div>
            <FormGroup row className="g-3" style={{ margin: 20 }}>
              <Row className="g-2">
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex  ${isMobile ? "flex-column" : ""}`}
                >
                  <Col md={5} sm={6}>
                    <Label
                      className="me-2"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Country <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col md={7} sm={6}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("country", e);
                      }}
                      value={inputData?.country}
                      options={countryArray}
                      // menuPlacement="top"
                      name="choices-single-default"
                      id="countryAddCompany"
                      className={`w-100 ${
                        inputDataError?.country ? "border border-danger" : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex  ${isMobile ? "flex-column" : ""}`}
                >
                  <Col md={5} sm={5}>
                    <Label
                      className="me-2"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      State <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col md={7} sm={7}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("state", e);
                      }}
                      value={inputData?.state}
                      name="state"
                      options={stateArray}
                      // menuPlacement="top"

                      className={`w-100 ${
                        inputDataError?.state ? "border border-danger" : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex  ${isMobile ? "flex-column" : ""}`}
                >
                  <Col md={5} sm={5}>
                    <Label
                      className="me-2"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      District <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col md={7} sm={7}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("dist", e);
                      }}
                      value={inputData?.dist}
                      name="dist"
                      options={districtArray}
                      // menuPlacement="top"

                      className={`w-100 ${
                        inputDataError?.dist ? "border border-danger" : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
              </Row>

              <Row className="g-2">
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex  ${isMobile ? "flex-column" : ""}`}
                >
                  <Col md={5}>
                    <Label
                      className="me-2"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Taluka <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col md={7} className={`${isMobile ? "" : "pl-5"}`}>
                    <Select
                      isMulti={false}
                      onChange={(e) => {
                        handleChangeInput("taluka", e);
                      }}
                      value={inputData?.taluka}
                      name="taluka"
                      options={talukaArray}
                      // menuPlacement="top"

                      className={`w-100 ${
                        inputDataError?.taluka ? "border border-danger" : ""
                      }`}
                    ></Select>
                  </Col>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className={`d-flex  ${isMobile ? "flex-column" : ""}`}
                >
                  <Col md={5}>
                    <Label
                      className="me-2"
                      style={{ fontWeight: 500 }}
                      for="exampleEmail"
                      md={12}
                    >
                      Village Name <span className="required_span"> * </span>
                    </Label>
                  </Col>
                  <Col md={7} className={`${isMobile ? "" : "pl-5"}`}>
                    <Input
                      id="exampleEmail"
                      name="villageName"
                      value={inputData?.villageName}
                      onChange={(e) => {
                        handleChangeInput(
                          e.target.name,
                          e.target.value.toUpperCase()
                        );
                      }}
                      className={`cust-input ${
                        inputDataError?.villageName ? "border-danger " : ""
                      } `}
                      type="text"
                    />
                  </Col>
                </Col>
                {isTablet && <Col md={5} className="mt-3"></Col>}

                <Col lg={1} md={1} sm={12} className="align-content-center">
                  <Button
                    type="submit"
                    disabled={submitLoading}
                    className={`custom-btn-page-layout btn text-white mx-3 ${
                      isTablet ? "" : "mx-3"
                    }`}
                    size="medium"
                    startIcon={
                      submitLoading ? (
                        <CircularProgress size={15} color="warning" />
                      ) : (
                        <Save />
                      )
                    }
                    fullWidth
                  >
                    {"Create"}
                  </Button>
                </Col>
              </Row>

              <Row className="g-3"></Row>
            </FormGroup>
          </div>
        </form>
      )}
      <Col md={12}>
        {/*<div className="" style={{ margin: 20 }}>
          <InputGroup className="search-inp">
            <Input
              value={searchInput}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              placeholder="Search For Village Name"
            />
            <InputGroupText>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15.1987 13.8333L10.9037 9.53833C11.6977 8.33158 12.0157 6.87314 11.7962 5.44537C11.5766 4.01761 10.8351 2.72208 9.71524 1.80963C8.59536 0.897181 7.17674 0.432709 5.73406 0.506144C4.29138 0.579579 2.92726 1.1857 1.90581 2.20715C0.884365 3.22859 0.278248 4.59271 0.204813 6.03539C0.131379 7.47807 0.595851 8.89669 1.5083 10.0166C2.42075 11.1365 3.71628 11.878 5.14404 12.0975C6.57181 12.317 8.03025 11.999 9.237 11.205L13.532 15.5L15.1987 13.8333ZM1.86534 6.33333C1.86534 4.03584 3.7345 2.16667 6.032 2.16667C8.3295 2.16667 10.1987 4.03584 10.1987 6.33333C10.1987 8.63083 8.3295 10.5 6.032 10.5C3.7345 10.5 1.86534 8.63083 1.86534 6.33333Z"
                  fill="#999999"
                />
              </svg>
            </InputGroupText>
          </InputGroup>
        </div> */}
        {loading ? (
          ""
        ) : (
          <div
            className="d-grid"
            style={{ margin: "0 20px", border: "1px solid #DEE2E6" }}
          >
            <TableContainer
              columns={column}
              data={villageList}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              showPagination={true}
              initialState={{ pageSize: 25, pageIndex: 0 }}
              divClass={"table-responsive"}
              exportfileName="Village list"
              exports={{
                excel: true,
                pdf: true,
                tally: false,
              }}
            />
            {/* <CustomPagination
              apiCallFunction={getVillageListAPi}
              rowDetails={villageList}
              totalCount={totalCount}
              limit={limit}
              offset={offset}
              setLimit={setLimit}
              setOffset={setOffset}
            /> */}
          </div>
        )}
      </Col>

      <DeleteModal
        deleteModal={deleteModal}
        handleDeleteRequest={handleDeleteRequest}
        setDeleteModal={setDeleteModal}
        deleteLoading={deleteLoading}
      />

      {editModal && (
        <EditVillage
          editModal={editModal}
          onCloseModal={() => setEditModal(false)}
          onSubmitModal={onSubmitModal}
          singleData={singleData}
        />
      )}
    </PageLayout>
  );
};

export default VillageList;
