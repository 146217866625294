import { useEffect, useMemo, useState } from "react";
import { productActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionHooks } from "../../common/useCheckPermission";
import Barcode from "react-barcode";
import { useForm } from "react-hook-form";
import { orange, purple } from "@mui/material/colors";
import { keys } from "../../config/keys";

const initialValues = {
  selectedProduct: null,
  productid: 0,
  height: 0,
  width: 0,
  weight: 0,
  barcodetext: "",
  startno: 0,
  totalbags: 0,
  capacity: 10,
};
export const useBagMaster = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialValues);
  const [showForm, setShowForm] = useState(false);
  const [bagBarcodedetails, setBagBarcodeDetails] = useState([]);
  const { products, bags_list } = useSelector((obj) => obj);

  const permission = usePermissionHooks(location);

  const onDropDownChange = (e, selectedOption) => {
    if (selectedOption === null) {
      setFormData((prev) => ({ ...prev, selectedProduct: null, productid: 0 }));
      setBagBarcodeDetails([]);
    } else {
      get_bags_barcode_details(selectedOption?.id);
      setFormData((prev) => ({
        ...prev,
        selectedProduct: selectedOption,
        productid: selectedOption?.id,
      }));
    }
  };

  const handleChange = (e) =>
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const get_bags_barcode_details = (id) => {
    dispatch(
      productActions.get_bags_barcode_details(id, {
        onSuccess: (res) => {
          setBagBarcodeDetails(res);
          setFormData((prev) => ({
            ...prev,
            startno: res[0].lastbagno + 1,
            totalbags: res[0].pendingbarcodetogenerate,
          }));
        },
      })
    );
  };

  const get_bags = (id) => {
    dispatch(
      productActions.get_bag_master_list({
        onSuccess: (res) => setLoading(false),
      })
    );
  };

  useEffect(() => {
    dispatch(
      productActions.get_product_list({
        onSuccess: (res) => {
          setLoading(false);
        },
        onError: (err) => toast.error(err),
      })
    );
    get_bags();
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      productActions.save_bags_barcode_details(formData, {
        onSuccess: (res) => {
          toast.success("Barcode created successfully");
          get_bags();
        },
        onError: (err) => console.log(err),
      })
    );
  };

  const onCancelClick = () => {
    setFormData((prev) => ({
      ...prev,
      ...initialValues,
    }));
  };

  const barlistColumn = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productName",
      },
      {
        Header: "Bag #",
        accessor: "bagno",
      },

      {
        Header: "Barcode",
        accessor: "barcodetext",
        Cell: (props) => (
          <Barcode
            value={props.value}
            height={30}
            displayValue={false}
            format={"CODE128"}
            fontSize={10}
          />
        ),
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return {
    products,
    bags: bags_list,
    permission,
    loading,
    setLoading,
    onDropDownChange,
    handleChange,
    formData,
    bagBarcodedetails,
    onSubmit,
    barlistColumn,
    onCancelClick,
    setShowForm,
    showForm,
  };
};
