/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PageLayout from "../../Component/PageLayout";
import { Col } from "reactstrap";

import TableContainer from "../../Component/TableContainer";
import { GridAction } from "../../common/useGridAction";
import { usePermissionHooks } from "../../common/useCheckPermission";
import { useLocation, useNavigate } from "react-router-dom";
import { useBagIssueListHooks } from "./useBagIssueHooks";
import { keys } from "../../config/keys";

const BagIssueList = () => {
  const navigate = useNavigate();
  const { bag_issue_list, loading } = useBagIssueListHooks();
  const location = useLocation();
  const permission = usePermissionHooks(location);

  const column = useMemo(
    () => [
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
      },
      {
        Header: "Farmer Name",
        accessor: "farmername",
      },
      {
        Header: "Total Bag Issued",
        accessor: "totalbagsissued",
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
      {
        ...GridAction({
          permission,
          onEditClick: (cellProps) => {
            navigate("/issuebag", { state: cellProps.row.original });
          },
        }),
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Bag Issue List"}
      loading={loading}
      addBtn={permission?.create ? true : false}
      backBtnTitle={`Add Bag Issue`}
      onClick={() => navigate("/issuebag")}
    >
      <Col md={12}>
        <div
          className="d-grid"
          style={{ margin: "20px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={bag_issue_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
            exportfileName="Bags Issue List"
          />
        </div>
      </Col>
    </PageLayout>
  );
};

export default BagIssueList;
