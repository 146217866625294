import React, { useEffect, useRef, useState } from "react";
import { Model } from "survey-core";
import {
  VisualizationPanel,
  VisualizationManager,
  NpsVisualizer,
} from "survey-analytics";
import { Tabulator } from "survey-analytics/survey.analytics.tabulator";

import { json } from "./analyticsjson";
import { Col, Form, Row } from "reactstrap";
import PageLayout from "../../Component/PageLayout";
import { useSurveyHooks } from "./useSurveyHooks";
import { Autocomplete, FormGroup, TextField } from "@mui/material";
import { textFieldProps } from "../../common/commonControlProps";
import { keys } from "../../config/keys";
import "survey-analytics/survey.analytics.css";
import "tabulator-tables/dist/css/tabulator.min.css";
import "survey-analytics/survey.analytics.tabulator.css";

VisualizationManager.registerVisualizer("rating", NpsVisualizer);

export const dataUrl = "https://api.surveyjs.io/private/surveys/nps/";
export const tabsInfo = [
  {
    name: "Analytical Report",
    vizPanel: undefined,
  },
  {
    name: "Table View",
    vizPanel: undefined,
  },
];

const SurveyAnalyatics = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const mainDiv = useRef();
  const vizPanelContainer = useRef();

  const { surveyList } = useSurveyHooks();
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  useEffect(() => {
    const survey = new Model(json);
    // fetch(dataUrl)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const dataFromServer = data.Data;
    //     const allQuestions = survey.getAllQuestions();
    //     for (var i = 0; i < tabsInfo.length; i++) {
    //       const tab = tabsInfo[i];
    //       const questions = allQuestions.filter(
    //         (question) => tab.questions.indexOf(question.name) > -1
    //       );

    //       console.log(questions);
    //       tab.vizPanel = new VisualizationPanel(questions, dataFromServer);
    //     }

    //     setLoaded(true);
    //     // document.getElementById("loadingIndicator")?.style.display = "none";
    //     // mainDiv.current.style.display = "";
    //     renderContainer(0);
    //   });
  }, []);

  const renderContainer = (index) => {
    const el = vizPanelContainer.current;

    console.log(tabsInfo);
    el.innerHTML = "";
    tabsInfo[index].vizPanel?.render(el);
  };

  const changeTab = (index) => {
    console.log(index);
    setTabIndex(index);
    renderContainer(index);
  };

  const renderTabs = () => {
    const tabs = [];
    for (var i = 0; i < tabsInfo.length; i++) {
      const className = "tablinks" + (tabIndex === i ? " active" : "");
      const index = i;
      const key = "tab_" + i;
      tabs.push(
        <button
          key={key}
          className={className}
          onClick={() => changeTab(index)}
        >
          {tabsInfo[index].name}
        </button>
      );
    }

    return tabs;
  };

  function LayoutEngine() {
    return {
      start: function () {},
      stop: function () {},
      update: function () {},
      destroy: function () {},
    };
  }

  const getSurveyQuestions = (e, selectedOption) => {
    console.log(selectedOption);
    setSelectedSurvey(selectedOption);
    const survey = new Model(selectedOption.surveyjsondata);
    fetch(`${keys().ServicePath}/api/survey/surveydata/${selectedOption.id}`)
      .then((response) => response.json())
      .then((data) => {
        setLoaded(true);
        const questions = survey.getAllQuestions();
        const answers = data.map((d) => JSON.parse(d.surveyJSONData));
        let tab = tabsInfo[0];

        // tab.vizPanel = new VisualizationPanel(questions, answers, {
        //   layoutEngine: new LayoutEngine(),
        //   allowDynamicLayout: false,
        // });
        tab.vizPanel = new VisualizationPanel(questions, answers);
        tab = tabsInfo[1];
        try {
          const ren = new Tabulator(survey, answers);
          tab.vizPanel = ren;
        } catch (ex) {
          console.log(ex);
        }
        changeTab(0);
      });

    // const s = survey
    //   .getAllQuestions(false)
    //   .forEach((q) =>
    //     questionsAnwers.push({ questionname: q.name, title: q.title })
    //   );
  };

  return (
    <PageLayout title={"Survey Form"} loading={loading}>
      <div className="m-4 g-2">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Autocomplete
                id="surveyId"
                name="surveyId"
                size="small"
                options={surveyList}
                clearText={true}
                value={selectedSurvey}
                onChange={getSurveyQuestions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...textFieldProps}
                    label="Select Survey  "
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col lg={12}>
            <div ref={mainDiv} style={{ display: !loaded ? "none" : "block" }}>
              <div className="tabs">{renderTabs()}</div>
              <div
                className="tabcontent"
                id={"reportdata"}
                ref={vizPanelContainer}
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default SurveyAnalyatics;
