/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import PageLayout from "../../Component/PageLayout";
import { useFarmingLandListHooks } from "./useLandDetailHooks";
import TableContainer from "../../Component/TableContainer";
import LoaderComponent from "../../Component/LoaderComponent";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { keys } from "../../config/keys";

const FarmingLandList = () => {
  const { loading, farmers_farming_land_list, permission } =
    useFarmingLandListHooks();
  const navigate = useNavigate();

  const column = useMemo(
    () => [
      {
        Header: "Farmer Code",
        accessor: "farmerCode",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Total Land (Sq. mt.)",
        accessor: "totallandsm",
      },
      {
        Header: "Total Plantation (Vigha)",
        accessor: "totalplantation",
      },
      {
        Header: "Created On",
        accessor: "createddatetime",
        Cell: ({ value }) => keys().shortDateTime(value),
      },
      {
        id: "Action",
        Header: () => (
          <span
            className="d-flex justify-content-center"
            style={{ textAlign: "center" }}
          >
            Action
          </span>
        ),
        Cell: (cellProps) => {
          return (
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="hstack gap-2">
                {/* Edit Button */}
                {permission?.edit && (
                  <IconButton
                    size="small"
                    color="success"
                    onClick={() =>
                      navigate("/landdetail", {
                        state: { farmerId: cellProps.row.original.id },
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                )}

                {/* Delete Button */}
              </div>
            </div>
          );
        },
      },
      // eslint-disable-next-line
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <PageLayout
      title={"Farming Land List"}
      loading={loading}
      addBtn={permission.create ? true : false}
      backBtnTitle={"Add Farming Land Details"}
      onClick={() => navigate("/landdetail", { state: { farmerid: 0 } })}
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <div
          className="d-grid"
          style={{ margin: "10px 20px", border: "1px solid #DEE2E6" }}
        >
          <TableContainer
            columns={column}
            data={farmers_farming_land_list || []}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            showPagination={true}
            initialState={{ pageSize: 20, pageIndex: 0 }}
            divClass={"table-responsive"}
          />
        </div>
      )}
    </PageLayout>
  );
};

export default FarmingLandList;
