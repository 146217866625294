import React, { useEffect, useState } from "react";

const PrintInvoice = React.forwardRef((props, ref) => {
  const { data, lists } = props;
  console.log("Invoice", data);
  const [productpercent, setproductpercent] = useState([]);

  const getType = (type) => {
    if (type === 1) return `(B)`;
    else if (type === 2) return `(D)`;
    else if (type === 3) return `(G)`;
    else return ``;
  };

  useEffect(() => {
    const totalNetweight = lists.purchases_from_farmer.reduce(
      (sum, row) => row.netweight + sum,
      0
    );
    const perc = [];

    data?.purchases.map((item) => {
      let netweight = 0,
        percentage = 0;
      netweight = item.data.reduce((sum, row) => row.netweight + sum, 0);

      percentage = (netweight / totalNetweight) * 100;

      if (item.data.length > 0) {
        if (item?.type === 1) {
          console.log(netweight, percentage);
          perc.push({
            id: 1,
            type: "Tobacco Leaves (B)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
          });
          setproductpercent([...perc]);
        } else if (item.type === 2) {
          perc.push({
            id: 2,
            type: "Tobacco Leaves (D)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
          });
          setproductpercent([...perc]);
        } else if (item.type === 3) {
          perc.push({
            id: 3,
            type: "Tobacco Leaves (G)",
            netweight: Number(netweight).toFixed(2),
            percentage: Number(percentage).toFixed(2),
          });
          setproductpercent([...perc]);
        }
      }
      return perc;
    });
  }, [lists.purchases_from_farmer, data?.purchases]);

  // useEffect(() => {
  //   if (productpercent.length > 0) {
  //     console.log(productpercent?.find((x) => x.id === 1).type);
  //   }
  // }, [productpercent]);

  return (
    <div className="row m-2" ref={ref} style={{ fontSize: "smaller" }}>
      <div className="col-12">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <h6>{`NISOL MANUFACTURING COMPANY PRIVATE LIMITED`}</h6>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <small>{data.addressLine1}</small>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <small>{data.addressLine2}</small>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <small>{data.addressLine3}</small>
          </div>
          <div className="col-12 d-flex justify-content-center fw-bold">
            <h6>{data?.unitname}</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {<b>{`Farmer Code #:`}</b>}
            {` ${data?.selectedFarmer?.farmerCode}`}
          </div>
          <div className="col-6">
            {<b>{`Date:`}</b>}
            {` ${data.voucherdate}`}
          </div>
          <div className="col-6">
            {<b>{`Farmer Name:`}</b>}
            {` ${data.name}`}
          </div>
          <div className="col-6">
            {<b>{`Invoice #:`}</b>}
            {` ${data.voucherno}`}
          </div>
          <div className="col-6">
            {<b>{`Village:`}</b>}
            {` ${data.villageName}`}
          </div>
          <div className="col-6">
            {<b>{`Vigha:`}</b>}
            {` ${data.totalbigha}`}
          </div>
          <div className="col-6">
            {<b>{`Variety:`}</b>}
            {` ${
              data?.purchases.length > 0 &&
              data?.purchases[0].data[0].productname
            }`}
          </div>
          {/* <div className="col-6">
            {<b>{`Variety:`}</b>}
            {` ${
              data?.purchasedetails.length > 0
                ? data?.purchasedetails[0].productname || ""
                : ""
            } `}
          </div> */}

          {/* <div className="col-6">
            {<b>{`Vigha:`}</b>}
            {` ${
              data?.purchasedetails.length > 0
                ? data?.purchasedetails[0].actuallandbigha
                : ""
            }`}
          </div>
         */}
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <table className="table table-sm table-bordered table-dark-bordered">
              <thead>
                <tr>
                  <th>{`Receipt`}</th>
                  <th>{`Product Name`}</th>
                  <th>{`HSN Code`}</th>
                  <th>{`Bags`}</th>
                  <th>{`Net KG`}</th>
                  <th>{`Price/Mann`}</th>
                  <th>{`Incentive`}</th>
                  {/* <th>{`Quality Incentive Amount`}</th> */}
                  <th>{`Amount`}</th>
                </tr>
              </thead>
              <tbody>
                {data?.purchases.map((item) => {
                  let bags = 0,
                    netweight = 0,
                    amount = 0;

                  return item.data.map((pd, idx) => {
                    bags += pd.actualbags;
                    netweight += pd.netweight;
                    amount += pd.amount;

                    return (
                      <>
                        <tr key={Math.random()}>
                          <td>{pd.voucherno}</td>
                          <td>{`Tobacco Leaves ${getType(item.type)}`}</td>
                          <td>{pd.hsncode}</td>
                          <td className="text-end">{pd.actualbags}</td>
                          <td className="text-end">
                            {Number(pd.netweight).toFixed(3)}
                          </td>
                          <td className="text-end">{pd.rateperunit}</td>
                          <td className="text-end">{pd.incentiveperkg}</td>
                          <td className="text-end">
                            {Number(pd.amount).toFixed(3)}
                          </td>
                        </tr>

                        {item.data.length - 1 === idx && (
                          <tr className="table-secondary">
                            <th>{}</th>
                            <th>{`Total`}</th>
                            <th>{``}</th>
                            <th className="text-end">{bags}</th>
                            <th className="text-end">
                              {Number(netweight).toFixed(3)}
                            </th>
                            <th>{}</th>
                            <th>{}</th>
                            <th className="text-end">
                              {Number(amount).toFixed(3)}
                            </th>
                          </tr>
                        )}
                      </>
                    );
                  });
                })}
                <tr className="table-warning">
                  <th>{}</th>
                  <th>{`Sub Total`}</th>
                  <th>{``}</th>
                  <th className="text-end">
                    {lists.purchases_from_farmer.reduce((sum, row) => {
                      return row.actualbags + sum;
                    }, 0)}
                  </th>
                  <th className="text-end">
                    {Number(
                      lists.purchases_from_farmer.reduce(
                        (sum, row) => row.netweight + sum,
                        0
                      )
                    ).toFixed(3)}
                  </th>
                  <th>{}</th>
                  <th>{}</th>
                  <th className="text-end">
                    {Number(
                      lists.purchases_from_farmer.reduce(
                        (sum, row) => row.amount + sum,
                        0
                      )
                    ).toFixed(3)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 mt-1">
            <table className="table table-sm table-bordered table-dark-bordered">
              <thead>
                <tr>
                  <th>{`Incentives`}</th>
                  <th>{`Applicable on`}</th>
                  <th>{`Is Eligible`}</th>

                  <th>{``}</th>
                  <th>{`Amount`}</th>
                </tr>
              </thead>
              <tbody>
                {data.incentives.map((item) => {
                  return (
                    <tr key={Math.random()}>
                      <td>{item.schemeAliasName}</td>
                      <td className="text-center">
                        {Boolean(item.pervigha)
                          ? "Vigha"
                          : Boolean(item.perkg)
                          ? "KG"
                          : "ACTUAL"}
                      </td>
                      <td className="text-center">
                        {item.incentiveconfigid > 0 ? "Yes" : "No"}
                      </td>
                      <td>{item.schemeAliasName}</td>
                      <td className="text-end">
                        {Number(item.incentiveamount).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="fw-bold text-end">{`Incentive Total`}</td>
                  <td className="text-end">
                    {Number(data?.incentivetotal).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="fw-bold text-end">{`Sub Total`}</td>
                  <td className="text-end">
                    {Number(data?.subtotal + data?.incentivetotal).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-end">{`Less Unaccount Bags (-)`}</td>
                  <td className="text-end">
                    {Number(data?.unaccountedbagsamount).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{`Product`}</td>
                  <td className="fw-bold">{`Net KG`}</td>
                  <td className="fw-bold">{`Percentage`}</td>
                  <td className="text-end">{`Transportation (+)`}</td>
                  <td className="text-end">
                    {Number(data?.totaltransportation).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 1).type) ||
                      ""}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 1).netweight) ||
                      "-"}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 1).percentage) ||
                      "-"}
                  </td>
                  <td className="fw-bold text-end">{`GRAND TOTAL`}</td>
                  <td className="text-end">
                    {Number(data?.grandtotal).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 2)?.type) ||
                      ""}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 2)?.netweight) ||
                      ""}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 2)?.percentage) ||
                      ""}
                  </td>
                  <td className="fw-bold text-end">{`TOTAL QTY (MANN)`}</td>
                  <td className="text-end">
                    {Number(data?.totalqtymaan).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 3)?.type) ||
                      ""}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 3)?.netweight) ||
                      ""}
                  </td>
                  <td className="text-end">
                    {(productpercent.length > 0 &&
                      productpercent?.find((x) => x.id === 3)?.percentage) ||
                      ""}
                  </td>
                  <td className="fw-bold text-end">{`PRICE/MANN`}</td>
                  <td className="text-end">
                    {Number(data?.pricepermaan).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>{`Remarks:`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintInvoice;
