/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SurveyManageValidationSchema } from "./validationSchema";
import { farmerActions, surveyActions } from "../../redux/actions";
import { statusOption } from "../../hooks/StaticData";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { keys } from "../../config/keys";
import { surveyJSON } from "./json.js";
import { useLocation } from "react-router";
import { Model } from "survey-react";
import { SurveyPDF } from "survey-pdf";

const initialValues = {
  id: 0,
  title: "",
  startdatestring: dayjs(Date()).format(keys().dbDateFormat),
  startdate: dayjs(Date()),
  enddatestring: dayjs(Date()).format(keys().dbDateFormat),
  enddate: dayjs(Date()),
  wefdatestring: dayjs(Date()).format(keys().dbDateFormat),
  wefdate: dayjs(Date()),
  jSONObj: {},
};

const surveyFormInitialValues = {
  id: 0,
  surveyId: 0,
  selectedSurvey: null,
  surveyJSONData: null,
  selectedFarmer: null,
  userTypeId: 0,
  userId: 0,
  surveyModel: null,
  farmerid: 0,
};

export const useSurveyHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { surveyList, farmerDropdownList } = useSelector((obj) => obj);
  const [formData, setFormData] = useState(initialValues);

  const [surveyData, setSurveyData] = useState({});
  const [rowData, setRowData] = useState([]);

  const [surveyFormData, setSurveyFormData] = useState(surveyFormInitialValues);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(SurveyManageValidationSchema),
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onStartDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      startdate: dateValue,
      startdatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onEndDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      enddate: dateValue,
      enddatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const onWEFDateChange = (dateValue) => {
    setFormData((prev) => ({
      ...prev,
      wefdate: dateValue,
      wefdatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    }));
  };

  const ondropdownchange = (e, selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      isactive: selectedOption?.value,
      selStatus: selectedOption || null,
    }));
  };

  const resetFormData = () => {
    // setFormData(initialValues);
    // setSelStatus(statusOption[0]);
  };

  const onSubmit = async () => {
    if (formData.id > 0) {
      // await dispatch(
      //   surveyActions..updateYieldConfiguration(
      //     { ...formData },
      //     () => {
      //       toast.success("Yield Configuration update successfully");
      //     }
      //   )
      // );
    } else {
      await dispatch(
        surveyActions.saveSurvey({ ...formData }, () => {
          toast.success("Survey create successfully");
        })
      );
    }
    // await dispatch(yieldConfigurationActions.getYieldConfiguration());
    setFormData(initialValues);
    // setDeleteModal(false);
    // setEditModal(false);
    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
  };

  const onUpdateSurveyDesign = (data) => {
    setFormData((prev) => ({
      ...prev,
      jSONObj: data,
    }));
  };

  const onSurveyHandleChange = (e, selectedOption) => {
    if (selectedOption !== null) {
      const survey = new Model(selectedOption.surveyjsondata);
      survey.onComplete.add(surveyComplete);

      setSurveyFormData((prev) => ({
        ...prev,
        surveyId: selectedOption?.value || 0,
        selectedSurvey: selectedOption || null,
        surveyJSONData: selectedOption.surveyjsondata || {},
        // surveyModel: survey,
      }));
    } else {
      setSurveyFormData((prev) => ({
        ...prev,
        ...surveyFormInitialValues,
      }));
    }
  };
  const onFarmerChange = (e, selectedOption) => {
    if (selectedOption !== null) {
      setSurveyFormData((prev) => ({
        ...prev,
        farmerid: selectedOption?.value || 0,
        userId: selectedOption?.value || 0,
        selectedFarmer: selectedOption || null,
      }));
    } else {
      setSurveyFormData((prev) => ({
        ...prev,
        selectedFarmer: null,
        farmerid: 0,
        userId: 0,
      }));
    }
  };

  const pdfDocOptions = {
    fontSize: 10,
  };

  const savePdf = function (surveyData) {
    const surveyPdf = new SurveyPDF(
      surveyFormData.surveyJSONData,
      pdfDocOptions
    );
    surveyPdf.data = surveyData;
    surveyPdf.save();
  };

  useEffect(() => {
    if (surveyFormData.surveyJSONData !== null) {
      const survey = new Model(surveyFormData.surveyJSONData);
      survey.onComplete.add(surveyComplete);
      // survey.addNavigationItem({
      //   id: "pdf-export",
      //   title: "Save as PDF",
      //   action: () => {
      //     console.log(survey.data);
      //     savePdf(survey.data);
      //   },
      // });
      console.log(survey);
      setSurveyFormData((prev) => ({
        ...prev,
        surveyModel: survey,
      }));
    }
  }, [surveyFormData.surveyJSONData]);

  useEffect(() => {
    //console.log(surveyFormData);
  }, [surveyFormData]);

  const surveyComplete = (sender, completeevent) => {
    console.log("surveyComplete", sender.data);
    console.log("surveyComplete completeevent", completeevent);
    console.log("surveyComplete surveyFormData", surveyFormData);

    const survey = new Model(surveyFormData.surveyJSONData);
    const q = survey.getAllQuestions().entries();
    const a = survey.getAllValues();

    let questionsAnwers = [];
    const s = survey
      .getAllQuestions(false)
      .forEach((q) =>
        questionsAnwers.push({ questionname: q.name, title: q.title })
      );

    console.log(questionsAnwers, a);

    if (surveyFormData.id > 0) {
    } else {
      console.log(JSON.stringify(sender.data));
      dispatch(
        surveyActions.saveSurveyData(
          { ...surveyFormData, surveyJSONData: JSON.stringify(sender.data) },
          () => {
            toast.success("Survey create successfully");
          }
        )
      );
    }
  };

  useEffect(() => {
    if (location?.state?.data) {
      const d = location?.state?.data;
      setFormData({
        id: d.id,
        title: d.title,
        startdatestring: dayjs(Date(d.startDate)).format(keys().dbDateFormat),
        startdate: dayjs(Date(d.startDate)),
        enddatestring: dayjs(Date(d.endDate)).format(keys().dbDateFormat),
        enddate: dayjs(Date(d.endDate)),
        wefdatestring: dayjs(Date(d.WefDate)).format(keys().dbDateFormat),
        wefdate: dayjs(Date(d.WefDate)),
        jSONObj: JSON.parse(d.surveyjsondata),
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch(surveyActions.getSurveyMaster());
    setFormData((prev) => ({ ...prev, selStatus: statusOption[0] }));
    get_farmerDropdownList();
  }, []);

  const get_farmerDropdownList = () =>
    dispatch(farmerActions.getFarmerDropdownList());

  useEffect(() => {
    if (surveyList.length > 0) {
      setRowData(surveyList);
    } else {
      setRowData([]);
    }
  }, [surveyList]);

  // useEffect(() => {
  //   if (editModal && singleData.id > 0) {
  //     setFormData(singleData);
  //     setFormData((prev) => ({
  //       ...prev,
  //       selStatus: statusOption.filter(
  //         (obj) => singleData.isactive === obj.value
  //       )[0],
  //     }));
  //   }
  // }, [editModal, singleData]);

  // const handleDeleteRequest = async () => {
  //   setDeleteLoading(true);
  //   let formData = new FormData();
  //   formData.append("id", deleteId);
  //   formData.append("isDelete", "Yes");

  //   if (deleteId > 0) {
  //     await dispatch(
  //       yieldConfigurationActions.deleteYieldConfiguration(deleteId)
  //     );
  //     await dispatch(yieldConfigurationActions.getYieldConfiguration());
  //     toast.success("Yield Configuration delete successfully");
  //     setDeleteLoading(false);
  //     setDeleteModal(false);
  //   }
  // };

  const onCancelClick = () => {
    setFormData(initialValues);
  };

  const onSurveyFormSubmit = async (survey) => {
    console.log("onSurveyFormSubmit", survey);
    const data = survey;
    // setSurveyFormData((prev) => ({
    //   ...prev,
    //   surveyJSONData: data,
    // }));
    // if (surveyFormData.id > 0) {
    // } else {
    //   console.log("save Call");
    //   await dispatch(
    //     surveyActions.saveSurveyData({ ...surveyFormData }, () => {
    //       toast.success("Survey create successfully");
    //     })
    //   );
    // }
  };

  // useEffect(() => {
  //   if (searchInput.length > 0) {
  //     const filteredData = rowData.filter((item) => {
  //       return (
  //         item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
  //         item.dakru.toString().includes(searchInput) ||
  //         item.gariyu.toString().includes(searchInput) ||
  //         item.bhukho.toString().includes(searchInput) ||
  //         item.estimated.toString().includes(searchInput) ||
  //         item.isStatus.toLowerCase().includes(searchInput.toLowerCase())
  //       );
  //     });
  //     setRowData(filteredData);
  //   }
  // }, [searchInput]);

  return {
    register,
    handleSubmit,
    errors,
    resetFormData,
    control,
    onSubmit,
    setFormData,
    formData,
    initialValues,
    handleChange,

    ondropdownchange,
    onCancelClick,
    onStartDateChange,
    onEndDateChange,
    onWEFDateChange,
    onUpdateSurveyDesign,
    onSurveyHandleChange,
    rowData,
    surveyList,
    surveyFormData,
    onSurveyFormSubmit,
    farmerDropdownList,
    onFarmerChange,
  };
};
